import React from 'react'
import leaf from '../images/logo/plant-2.png'
import '../pages/Filter.css'

function ImageButton(props) {
    function handleClick() {
        var image1 = props.src;

        var displayImage = document.getElementById(props.id);

        //image ID with 1 digit, less than 10
        const ID_1DIGIT = 6;

        //index base for images has index has 2 digits/greater than 9
        const BASE = 10;

         //get index
         let index;
         //if image id has length greater than 6, the index has 2 digits
         if(props.id.length > ID_1DIGIT){
             let last_digit = props.id[ID_1DIGIT] - 1;
             index = BASE + last_digit;
         }else{
             index = props.id[ID_1DIGIT - 1] - 1;
         }

         //1 is true and 0 is false
        if (displayImage.src.match(image1)) {
            displayImage.src = leaf;
            props.buttonArray[index] = 1;
            console.log(props.buttonArray);
        } else {
            displayImage.src = image1;
            props.buttonArray[index] = 0;
            console.log(props.buttonArray);
        }
    }

    return (
        <button className='filterItem' onClick={handleClick}>
            <img class="filterImg" id={props.id} src={props.src} alt='' />
            <h3 className='filterText'>{props.text}</h3>
        </button>
    )
}

export default ImageButton
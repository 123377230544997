import React from 'react';
import './Questions.css';
import {useNavigate} from 'react-router-dom';


function SelectPeriod() {

  const navigate = useNavigate();

  function handleAddPlantClick(){
      navigate(`/myPlants`);
    }
  function handleClick(date){
    localStorage.setItem('duration',date*30);
      navigate(`/selectDate`);
    }
  
  return (

    <div class='bg'>

      <h3 class='title'>How long would you like to be notified?</h3>

      <div class='questionContainer'>

        <button class='periodAndDateButton' onClick={() => handleAddPlantClick()}>Not Notify</button>

        <button class='periodAndDateButton' onClick={() => handleClick(3)}>3 Months</button>

        <button class='periodAndDateButton' onClick={() => handleClick(6)}>6 Months</button>

        <button class='periodAndDateButton' onClick={() => handleClick(9)}>9 Months</button>

       <button class='periodAndDateButton' onClick={() => handleClick(12)}>12 Months</button>

      </div>

    </div>
  );
}

export default SelectPeriod;
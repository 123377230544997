import React from 'react';
import ToPreviousPage from '../components/ToPreviousPage';
import './Account.css';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect} from "react";


function SignUp() {

  const navigate = useNavigate();

  const[submitState, setSubmitState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [error, setErrorState] = useState(null);

  const send = 'Send';

  const resend = 'Resend';

  const [sendBtnText, setSendBtnText] = useState(send);
  
  const[sendBtnState, setSendBtnState] = useState(true);
  const [email, setEmail] = useState('');
  
  const [timer, setTimer] = useState(0); 
  
  var countdown = 'Resend('+ timer +'s)';

  localStorage.setItem('lastPage', window.location.href)

  useEffect(() => {

    let interval;

    if (sendBtnState && timer > 0) {

      interval = setInterval(() => {
        setTimer(prevSeconds => prevSeconds - 1);
      }, 1000);
      setSendBtnText(countdown);   

    } else if (timer === 0 && document.getElementById('emailAddr').value !== '' && sendBtnText !== send) {

      setSendBtnText(resend);
      setSendBtnState(false);

    }

    return () => clearInterval(interval);
  }, [countdown, sendBtnText, sendBtnState, timer]);
  
  function resetTimer() {
    if (timer === 0) {
      setTimer(60);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    setLoading(true);
    const apiurl = 'https://api.flora-friend.com/user/get_code/';
    const obj = {
      method : "sign_up",
      email : emailAddr.value,
    };

    fetch(apiurl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          setResponseMessage(data.message || 'code was sent');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        setResponseMessage(error.message);
      });
      resetTimer();
      setSendBtnText(countdown);
      setSendBtnState(true);
  };

  function enableSendButton(){
    if (document.getElementById('emailAddr').value !== '' && timer === 0){
      setSendBtnState(false);
    } else {
      setSendBtnState(true);
    }
  }

  function enableSubmitButton(){
    if (document.getElementById('emailAddr').value === '' || 
        document.getElementById('password').value === '' ||
        document.getElementById('cPassword').value === '' ||
        document.getElementById('verification').value === ''){
      setSubmitState(true);
    } else {
      setSubmitState(false);
    }
  }

  //input values
  const vercode = document.getElementById('verification');
  const password = document.getElementById('password');
  const confirmPassword = document.getElementById('cPassword');
  const emailAddr = document.getElementById('emailAddr');

  //set Error messages and effect
  const setError = (element, message) =>{
    const input = element.parentElement;
    const error = input.querySelector('.error');

    error.innerText = message;
    input.classList.add('error');
  }

  //remove error messages and effect
  const setSuccuss = element => {
    const input = element.parentElement;
    const error = input.querySelector('.error');

    error.innerText = '';
    input.classList.remove('error');
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    if(validationCheck()){
      console.log("true");

      const apiurl = 'https://api.flora-friend.com/user/verify_code/';
      const obj = {
        method : "sign_up",
        email: document.getElementById('emailAddr').value,
          code: document.getElementById('verification').value,
          password: document.getElementById('cPassword').value,
      };
  
      fetch(apiurl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            setResponseMessage(data.message || 'code was sent');
            window.setTimeout(navigation, 2000);
          }
          if (data.error === "code incorrect") {
            setResponseMessage(data.message || 'wrong code');
            setError(vercode, 'Wrong Verification Code');
          }
          setLoading(false);
        })
        .catch((error) => {
          setErrorState(error);
          setLoading(false);
          setResponseMessage(error.message);
        });

        // window.setTimeout(navigation, 2000);
    }
  }

  function navigation(){
    navigate('/logIn');
  }

  //validate Email Input
  const isValidEmail = email => {
    //regular expression for email
    const emailRE =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRE.test(String(email).toLowerCase());
  }

  const isValidPassword = pass => {
    const passRE = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    return pass.match(passRE);
  }

  var isValid = 1;

  function validationCheck(){

      //check email input value
      if(!isValidEmail(emailAddr.value)){
        setError(emailAddr, 'invalid Email address');
        isValid = 0;
      }else{
        setSuccuss(emailAddr);
      }
      //check password input value
      if(password.value.length < 8){
        setError(password, 'password too short');
        isValid = 0;
      }else if(!isValidPassword(password.value)){
        setError(password, 'password does not match required form');
        isValid = 0;
      }
      else{
        setSuccuss(password);
      }

      //check 'confirm password' matches password
      if(confirmPassword.value !== password.value){
        setError(confirmPassword, "password doesn't match");
        isValid = 0;
      }else{
        setSuccuss(confirmPassword);
      }

      //check verification code input value
      if(!/^\d{6}$/.test(vercode.value)){
        setError(vercode, 'Verification code must be at 6 digits');
        isValid = 0;
      }else{
        setSuccuss(vercode);
      }

      if(isValid == 1){
        return true;
      }
  }

  return (

    <div class='bg'>
      <ToPreviousPage path='/logIn' msg='Previous Page'/>

      <div className='formContainer'>

        <form id = 'form' onChange={enableSubmitButton} onSubmit={handleSubmit}>
          
          <h2>Sign Up</h2>

          <div className='inputContainer'>
            <label for="emailAddr">Email:</label>
            <input 
              className='accountInput'
              type='text' 
              inputMode='email' 
              id='emailAddr' 
              placeholder='John@domain.com' 
              onChange={enableSendButton}
            />
            <div className='error'> </div>
          </div>

          <div className='inputContainer'>
            <label for="password">Password:</label>
            <input 
              className='accountInput'
              type='password' 
              inputMode='password' 
              id='password'
            />
            <div class = 'error'></div>

            <div className='text-container'>
              <p class='passwordRequirements'>Password needs at least: </p>

              <ul class='requirements'>

                <li>1 uppercase letter</li>

                <li>1 lowercase letter</li>

                <li>8 - 16 characters</li>

              </ul>
            </div>
          </div>

          <div className='inputContainer'>
            <label for="cPassword">Confirm Password:</label>
            <input 
              className='accountInput'
              type='password' 
              inputMode='password' 
              id='cPassword'
            />
            <div className='error'></div>
          </div>

          <div className='inputContainer'>
            <label for="verification">Verification Code:</label>
            <div className='verificationContainer'>
              <input 
                className='accountInput'
                type='text' 
                inputMode='numeric' 
                id='verification' 
                patter='^\d{6}$'
              />
              <button class='sendCode' disabled={sendBtnState} onClick={handleClick}>{sendBtnText}</button>
              <div className='error'></div>
            </div>
          </div>

          <button type = 'submit' class='signUpAndResetButton' disabled={submitState} >Sign Up</button>

        </form>
      
      </div>

    </div>
  );
}

export default SignUp;
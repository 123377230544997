import React ,{ useState, useEffect}from 'react';
import ToPreviousPage from '../components/ToPreviousPage';
import { useLocation } from 'react-router-dom';
import '../pages/Filter.css'
import './Results.css'
import { useNavigate } from 'react-router-dom';

function Results() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filterQuery = searchParams.get('conditions');
  const decodedFilterQuery = decodeURIComponent(filterQuery);
  const filterItems = ["Melbourne","Sydney","spring","summer","autumn","winter","vegetable","fruit","flower",
                        "herb", "full sun", "part sun", "part shade", "full shade"];

  let indices = [];
  const filterCondition = JSON.parse(decodedFilterQuery);

  localStorage.setItem('filterResults', filterQuery)

  filterCondition.forEach((value, index) => {
      if (value === 1) {
        indices.push(index);
      }
  });

  const locationIndices = [0, 1]; // Indices for locations
  const seasonIndices = [2, 3, 4, 5]; // Indices for seasons
  const typeIndices = [6, 7, 8, 9]; // Indices for types
  const lightConditionIndices = [10, 11, 12, 13]; // Indices for light conditions

  const obj = {
    method : "filtering",
    location: locationIndices.filter(index => indices.includes(index)).map(index => filterItems[index]),
    season: seasonIndices.filter(index => indices.includes(index)).map(index => filterItems[index]),
    type: typeIndices.filter(index => indices.includes(index)).map(index => filterItems[index]),
    light_condition: lightConditionIndices.filter(index => indices.includes(index)).map(index => filterItems[index])
  };


  useEffect(() => {
    const apiurl = 'https://api.flora-friend.com/response/resp/';
    fetch(apiurl,{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type' : 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('data',data);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [filterQuery]);


  function handleClick(name){
    navigate(`/plantInfo?search=${encodeURIComponent(name)}`);
  }

    return (
  
      <div className='bg'>
        <div> 
            <ToPreviousPage path='/filter' msg='Modify Constraints'/>
        </div>

        {/* Display loading state, error, or data */}

        <div class='results'>
          {loading ? (
            <h3 className='errorText'>Loading...</h3>
          ) : error ? (
            <h3 className='errorText'>Error: {error.message}*</h3>
          ) : (
            <div className='results'>
              {data && data.length > 0 ? (
                <div>
                  {data.map((plant, index) => {
                    const imageUrl = `data:image/jpeg;base64,${plant.image}`;

                    return (
                      <button className='resultsItem' onClick={() => handleClick(plant.name)}>
                        <div className='center'>
                          <img class="resultsImg" src={imageUrl} alt='' />
                          <h3 className='resultsText'>{plant.name}</h3>
                        </div>
                      </button>
                    );
                  })}
                </div>
              ) : (
                <h3 className='errorText'>No results found</h3>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  
  export default Results;
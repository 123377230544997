import React from 'react';
import ToPreviousPage from '../components/ToPreviousPage';
import {useNavigate} from 'react-router-dom';
import './PlantInfo.css';


function Profile() {
  
  const navigate = useNavigate();

  return (

    <div class='bg'>

      <ToPreviousPage path='/results' msg='Browse More'/>

      <div>
          <h2 class='welcome'>Profile</h2>
      </div>

      <button class='addPlant' onClick={()=>{navigate('/selectPeriod')}}>Add Plant</button>
      
    </div>
  );
}

export default Profile;
import React from 'react';
import './Questions.css';
import {useNavigate} from 'react-router-dom';

function SelectMethod() {
  
  const navigate = useNavigate();
  function handleClick(method){
    localStorage.setItem('notification_method',method);
    if (method == 1){
      navigate('/email')
    }
    if (method == 2){
      navigate('/link')
    }
    }

  return (

    <div class='bg'>

      <h3 class='title'>How would you like to be notified?</h3>

      <div class='questionContainer'>

        <button class='methodButton' onClick={() => handleClick(1)}>Email</button>

        {/* <button class='methodButton' onClick={() => handleClick(2)}>Calendar</button> */}

      </div>

      {/* <h3 class='calendar'>Supported Calendars: Google, Apple, Outlook.</h3> */}

    </div>
  );
}

export default SelectMethod;
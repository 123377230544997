import React, { useEffect } from 'react';
import ToPreviousPage from '../components/ToPreviousPage'
import { BsArrowRightSquareFill } from "react-icons/bs";
import {useNavigate} from 'react-router-dom';


//import images
import ImageButton from '../components/ImageButton';
import mel from '../images/loc/mel.jpg'
import syd from '../images/loc/syd.jpg'
import spring from "../images/season/spring.jpg"
import summer2 from '../images/season/summer_2.jpg'
import fall2 from '../images/season/full_2.jpg'
import winter2 from '../images/season/winter_2.jpg'
import veg from "../images/type/veg.jpg"
import fruit from '../images/type/fruit.jpg'
import flower2 from '../images/type/flower_2.jpg'
import herb from "../images/type/herb.jpg"
import fullSun from '../images/lc/fsun.jpg'
import partialSun from '../images/lc/psun.jpg'
import partialShade from '../images/lc/pshade.jpg'
import fullShade from '../images/lc/fshade.jpg'

function Filter() {

  const navigate = useNavigate();

  const buttonArray = Array(14).fill(0);
  var conditions;

  function handleSubmit(){
    conditions = encodeURIComponent(JSON.stringify(buttonArray));
    navigate(`/results?conditions=${conditions}`);}
    

  return (

    <div className='bg'>
        <ToPreviousPage path='/' msg='Previous Page' />

        <div className='filter'>
          <div className='filterContainer'>

          <h2 className='filterTitle'>Location</h2>
            <div className='filterItems'>
              <ImageButton 
                id="image1" src={mel} text={'MEL'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image2" src={syd} text={'SYD'} buttonArray={buttonArray}
              />
            </div>

            <h2 className='filterTitle'>Season</h2>

            <div className='filterItems'>
              <ImageButton 
                id="image3" src={spring} text={'Spring'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image4" src={summer2} text={'Summer'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image5" src={fall2} text={'Autumn'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image6" src={winter2} text={'Winter'} buttonArray={buttonArray}
              />

            </div>

            <h2 className='filterTitle'>Type</h2>
            <div className='filterItems'>
              <ImageButton 
                id="image7" src={veg} text={'Veg'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image8" src={fruit} text={'Fruit'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image9" src={flower2} text={'Flower'} buttonArray={buttonArray}
                />
              <ImageButton 
                id="image10" src={herb} text={'Herb'} buttonArray={buttonArray}
              />
            </div>

            <h2 className='filterTitle'>Light Condition</h2>
            <div className='filterItems'>
              <ImageButton 
                id="image11" src={fullSun} text={'Full sun'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image12" src={partialSun} text={'Partial sun'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image13" src={partialShade} text={'Partial shade'} buttonArray={buttonArray}
              />
              <ImageButton 
                id="image14" src={fullShade} text={'Full shade'} buttonArray={buttonArray}
              />
            </div> 
            <button className='submit' onClick={handleSubmit}>
              Submit
              <BsArrowRightSquareFill style={{verticalAlign:'top', paddingLeft:'3%'}}/>
            </button>
          </div>
        </div>
        
    </div >

  );
}

export default Filter;
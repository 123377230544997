import React from 'react';
import './Home.css';
import {useNavigate} from 'react-router-dom';


function Home() {

  const navigate = useNavigate();

  return (
    <div class='bg'>

      <div>

        <h1 class='welcome'>Welcome to Flora Friend!</h1>

        <button class='homeButton' onClick={()=>{navigate('/filter')}}>Look for Inspirations</button>

        <button class='homeButton' onClick={()=>{navigate('/search')}}>Search for Plants</button>

        <button class='homeButton' onClick={()=>{navigate('/myPlants')}}>My Plants</button>

      </div>
    
    </div>
  );
}

export default Home;
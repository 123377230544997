import React, { useState, useEffect } from 'react';
import ToPreviousPage from '../components/ToPreviousPage'
import { useLocation ,useNavigate} from 'react-router-dom';
import './PlantInfo.css';

function PlantInfo(){

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('search');
    const modifiedQuery = 'search ' + searchQuery;
    const modifiedQueryEncoded = encodeURIComponent(modifiedQuery);
    const navigate = useNavigate();
  
    useEffect(() => {
      const apiurl = 'https://api.flora-friend.com/response/resp/';
      fetch(apiurl,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type' : 'text/plain',
        },
        body: modifiedQueryEncoded.replace(/%20/g," "),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }, [searchQuery, modifiedQueryEncoded]);

    function handleClick(name,id){
      if (localStorage.getItem('email') !== null) {
        localStorage.setItem('plantID',id);
        navigate(`/selectPeriod`);
      }
      if (localStorage.getItem('email') === null){
        navigate('/login');
      }
      localStorage.setItem('isFilter', 1)
    }
  
    return(
        <div className='bg'>
      <div>
      <ToPreviousPage path = '/filter' msg='Search More'/>
        {/* <h2 class='parisienne-regular'><strong>Input:</strong> {searchQuery}</h2> */}
      </div>

      <div className='container'>
        {/* Display loading state, error, or data */}
        {loading ? (
            <h3 className='errorText'>Loading...</h3>
          ) : error ? (
            <h3 className='errorText'>Error: {error.message}</h3>
          ) : (
            <div>
              {data && data.length > 0 ? (
                <div>
                  {data.map((plant, index) => {
                    const imageUrl = `data:image/jpeg;base64,${plant.image}`;
                    const season = plant.season;
                    const seasonText = season.join(', ');
                    const light = plant.light_condition;
                    const lightText = light.join(', ');
                    return (
                      <div>
                        <div className='info'>
                          <img className='infoImg' src={imageUrl} alt={plant.name} />
                          <h3 className='infoTitle'> {plant.name} </h3>
                          <div className='infoText'>
                              <h3>Type: {plant.type}</h3>
                              <h3>Soil: {plant.soil_requirement}</h3>
                              <h3>Water: {plant.watering_frequency}</h3>
                              <h3>Climate Zone: {plant.climate_zone}</h3>
                              <h3>Light Condition: {lightText}</h3>
                              <h3>Plant Season : {seasonText}</h3>
                          </div>
                        </div>
                        <button className='addPlant' onClick={() => handleClick(plant.name,plant.plant_id)}>Add Plant</button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <h3 className='errorText'>No results found</h3>
              )}
            </div>
          )}
      </div>
    </div>
  );
}

export default PlantInfo;
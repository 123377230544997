import React, { useEffect, useState } from 'react';
import ToPreviousPage from '../components/ToPreviousPage';
import './Account.css'
import './LogIn.css'
import { useNavigate } from 'react-router-dom';

function LogIn() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitState, setSubmitState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setErrorState] = useState(null);
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState(null);


  const enableSubmitButton = () => {
    setSubmitState(email === '' || password === '');
  };

  //validation for email & password
  //input values
  const emailAddr = document.getElementById('emailAddr');
  const passwordVar = document.getElementById('password');
  const form = document.getElementById('form');

  //set Error messages and effect
  const setError = (element, message) =>{
    const input = element.parentElement;
    const error = input.querySelector('.error');

    error.innerText = message;
    input.classList.add('error');
  }

  const setMessage = message =>{
    const input = form.parentElement;
    const error = input.querySelector('.loginErrorText');
    error.innerText = message;
    console.log(error);
  }

  //remove error messages and effect
  const setSuccuss = element => {
    const input = element.parentElement;
    const error = input.querySelector('.error');

    error.innerText = '';
    input.classList.remove('error');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessage('');
    setSuccuss(passwordVar);
    
    if(validationCheck()){
      setLoading(true);
    const apiurl = 'https://api.flora-friend.com/user/sign_in/';
    const obj = {
      email,
      password,
    };

    fetch(apiurl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          setResponseMessage(data.message || 'Login successful!');
          localStorage.setItem('email', document.getElementById('emailAddr').value);
          if(localStorage.getItem('lastPage')){
            navigate('/')
          }else{
            window.history.back()
          }
          
        }else{
          console.log("Wrong");
          setMessage('Wrong Email or Password');
          setError(passwordVar, '');
          setError(emailAddr, '')
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrorState(error);
        setLoading(false);
        setResponseMessage(error.message);
        setMessage('Error: ' + error.message);
      });
    }
  };

  useEffect(() => {
    enableSubmitButton();
  }, [email, password]);

  //validate Email Input
  const isValidEmail = email => {
    //regular expression for email
    const emailRE =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRE.test(String(email).toLowerCase());
  }

  function validationCheck(){

    //check email input value
    if(!isValidEmail(emailAddr.value)){
      setError(emailAddr, 'invalid Email address');
    }else{
      setSuccuss(emailAddr);
      return true;
    }
  }




  return (

    <div class='bg'>

      <ToPreviousPage path='/' msg='Previous Page'/>

      <h2 class='accountFunc'>Log In with Email</h2>

      <div class='logInContainer'>
        <form id='form' onSubmit={handleSubmit}>

          <div className='inputContainer'>
            <label for="emailAddr" htmlFor="email">Email:</label>
            <input 
              className='accountInput'
              type='text' 
              inputMode='email' 
              id='emailAddr' 
              placeholder='John@domain.com'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className='error'> </div>
          </div>
          
          <div className='inputContainer'>
            <label for="password" htmlFor="password">Password:</label>
            <input 
              className='accountInput'
              type='password' 
              inputMode='password' 
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className='error'> </div>
            <p><a class='forgotPassword' href='/resetPassword'>Forgot Password?</a></p>
          </div>

          <button type='submit' className='logInButton' disabled={submitState || loading}>
            {loading ? 'Logging In...' : 'Log In'}
          </button>
          
        </form>
        <p class='signUp'>Dont have an account? <a class='signUpLink' href='/signUp'>Sign Up</a></p>
        <h3 className='loginErrorText'></h3>
      </div>

    </div>
  );
}

export default LogIn;
import React from 'react';
import ToPreviousPage from '../components/ToPreviousPage';


function ZeroResults() {

  return (

    <div class='bg'>

      <ToPreviousPage path='/search' msg='Previous Page'/>

      <p style={{fontSize:'300%', color:'white', width:'100vw', textAlign:'center'}}>0 Results</p>

    </div>
  );
}

export default ZeroResults;
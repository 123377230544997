import React, { useState, useEffect } from 'react';
import ToPreviousPage from '../components/ToPreviousPage';
import { useLocation, useNavigate } from 'react-router-dom';
import './Search.css'
import './Results.css'

function MyPlants() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPlants();
  }, []); // Run only on initial mount

  const fetchPlants = () => {
    const email = localStorage.getItem('email');

    if (email === null) {
      navigate('/login');
      return; // Exit early if not logged in
    }

    const obj = { email };

    const apiurl = 'https://api.flora-friend.com/user/return_my_plants/';
    fetch(apiurl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('data', JSON.stringify(data)); // Store as string
        setData(data); // This triggers a re-render
        setLoading(false);
      })
      .catch((error) => {
        setError(error); // This will also trigger a re-render
        setLoading(false);
      });
  };

  const handleClick = (plantId) => {
    const Plantobj = { id: plantId };

    const apiurl = 'https://api.flora-friend.com/user/remove_plant/';
    fetch(apiurl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(Plantobj),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        // After removing the plant, re-fetch the updated plant list
        fetchPlants(); // This will trigger a re-render
      })
      .catch((error) => {
        setError(error); // This will also trigger a re-render
      });
  };

  return (
    <div class='bg'>
      <div>
        <ToPreviousPage path='/' msg='Previous Page'/>
      </div>

      <div className='search'>
        <div className='myPlantContainer'>
          {loading ? (
            <h3 className='errorText'>Loading...</h3>
          ) : error ? (
            <h3 className='errorText'> Error: {error.message}</h3>
          ) 
          : (
            <div>
              {data && data.length > 0 ? (
                <div>
                  {data.map((plant, index) => {
                    const imageUrl = `data:image/jpeg;base64,${plant.image}`;

                    return (
                        <div className='myPlantItem'>
                          <div className='center'>
                            {<img class="resultsImg" src={imageUrl} alt='' />}
                            <div className='infoText'>
                              {<div className='myPlantText'>{plant.name}</div>}
                              {<button className='remove' onClick={() => handleClick(plant.plant_has_object_id)}>remove</button>}
                            </div>
                          </div>
                        </div>
                    );
                  })}
                  {/* <p3 className = 'message'>{loadMore && 'Loading...'}</p3> */}
                </div>
              ) : (
                <p className="errorText">No results found</p>
              )}
              <div id="scroll-anchor" style={{ height: '20px' }}></div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyPlants;
import React, { useState, useEffect } from 'react';
import ToPreviousPage from '../components/ToPreviousPage'
import { useLocation ,useNavigate} from 'react-router-dom';
import './Subscription.css';


function Email() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const obj = {
     email: localStorage.getItem('email'),
     plant:localStorage.getItem('plantID'), 
     start_date:localStorage.getItem('start_date'), 
     add_date:localStorage.getItem('add_date'),
     duration:localStorage.getItem('duration'), 
     notification_method:"1"
  };

  function handleClick(){
    const isFilter = localStorage.getItem('isFilter')
    if(isFilter === '1'){
      const conditions = encodeURIComponent(localStorage.getItem('filterResults'))
      navigate(`/results?conditions=${conditions}`)
    }else{
      console.log(isFilter)
      navigate('/search')
    }
  }


  useEffect(() => {
    const apiurl = 'https://api.flora-friend.com/user/add_plant/';
    fetch(apiurl,{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type' : 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('data',data);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (

    <div class='bg'>

      <h2 class='subscribedSuccessfully'> Subscribed Successfully! </h2>

      <p class='msg'>You will receive an email when your plant needs to be watered.</p>

      <div class='hyperLinkContainer'>

        <p><a class='hyperLink' href='/'>Go to Homepage</a></p>

        <p><a class='hyperLink' onClick={handleClick}>Browse More</a></p>

        <p><a class='hyperLink' href='/myPlants'>Go to My Plants</a></p>

      </div>

    </div>
  );
}

export default Email;
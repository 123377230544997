import './App.css';
import React from 'react';
import Search from './pages/Search';
import Filter from './pages/Filter';
import Home from './pages/Home';
import Results from './pages/Results';
import SearchResult from './pages/SearchResult';
import PlantInfo from './pages/PlantInfo';
import ZeroResults from './pages/ZeroResults';
import Profile from "./pages/Profile";
import MyPlants from "./pages/MyPlants";
import LogIn from "./pages/LogIn";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import SelectPeriod from './pages/SelectPeriod';
import SelectMethod from './pages/SelectMethod';
import Email from "./pages/Email";
import Link from "./pages/Link";
import SelectLastDateWatered from './pages/SelectLastDateWatered';


import {
  Routes,
  Route,
} from 'react-router-dom';


function App() {
  
  return (
    <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='/search' element={<Search/>}/>
      <Route exact path='/filter' element={<Filter/>}/>
      <Route exact path='/results' element={<Results/>}/>
      <Route exact path='/plantInfo' element={<PlantInfo/>}/>
      <Route exact path='/searchResult' element={<SearchResult/>}/>
      <Route exact path='/zeroResults' element={<ZeroResults/>}/>
      <Route exact path='/profile' element={<Profile/>}/>
      <Route exact path='/myPlants' element={<MyPlants/>}/>
      <Route exact path='/logIn' element={<LogIn/>}/>
      <Route exact path='/signUp' element={<SignUp/>}/>
      <Route exact path='/resetPassword' element={<ResetPassword/>}/>
      <Route exact path='/selectPeriod' element={<SelectPeriod/>}/>
      <Route exact path='/selectMethod' element={<SelectMethod/>}/>
      <Route exact path='/email' element={<Email/>}/>
      <Route exact path='/link' element={<Link/>}/>
      <Route exact path='/selectDate' element={<SelectLastDateWatered/>}/>
    </Routes>
  );
}

export default App;
import React from 'react';
import './ToPreviousPage.css'
import { useNavigate } from 'react-router-dom';
import { TbSquareArrowLeftFilled } from "react-icons/tb";

function ToPreviousPagePage(props) {

    const navigate = useNavigate();

    return (
        
        <button class='previousPageButton' onClick={() => { navigate(props.path) }}>

            <div class='previousPageButtonContent'>

                <TbSquareArrowLeftFilled size={32} style={{ verticalAlign: 'bottom' }}/> {props.msg}

            </div>

        </button>
    );
}

export default ToPreviousPagePage;
import React, { useState, useEffect } from 'react';
import ToPreviousPage from '../components/ToPreviousPage'
import { useLocation ,useNavigate} from 'react-router-dom';
import './Subscription.css';


function Link() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const obj = {
     email: localStorage.getItem('email'),
     plant:localStorage.getItem('plantID'), 
     start_date:localStorage.getItem('start_date'), 
     add_date:localStorage.getItem('add_date'),
     duration:localStorage.getItem('duration'), 
     notification_method:"2"
  };


  useEffect(() => {
    const apiurl = 'https://api.flora-friend.com/user/add_plant/';
    fetch(apiurl,{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type' : 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('data',data);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [obj]);

  let initialText = 'Copy';

  let copied = 'Copied';

  let [copyButtonText, setCopyButtonText] = useState(initialText);

  function copyLink(text){
    navigator.clipboard.writeText(text);
    setCopyButtonText(copied);
  }

  return (

    <div class='bg'>

      <h2 class='subscribedSuccessfully'> Copy the link to your Calendar </h2>

      <p id='calendarLink' class='msg'>https://------------------------------------------</p>

      <button class='copyButton' onClick={() => copyLink(document.getElementById('calendarLink').textContent)}>{copyButtonText}</button>

      <div class='hyperLinkContainer'>

        <p><a class='hyperLink' href='/'>Go to Homepage</a></p>

        <p><a class='hyperLink' href='/search'>Browse More</a></p>

        <p><a class='hyperLink' href='/myPlants'>Go to My Plants</a></p>

      </div>

    </div>
  );
}

export default Link;
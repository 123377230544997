import React ,{ useState, useEffect,useRef }from 'react';
import ToPreviousPage from '../components/ToPreviousPage';
import './Search.css';
import './Results.css'
import { FaSearch } from "react-icons/fa";
import {useNavigate} from 'react-router-dom';


function Search() {
  const navigate = useNavigate();
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchStartid, setSearchStartid] = useState(1); 
  const [loadMore, setLoadMore] = useState(false);
  const observer = useRef();
  useEffect(() => {
    const fetchData = async () => {
      const apiurl = 'https://13.211.145.221/response/all/';
      const obj = {
          id: searchStartid,
      };

      try {
        // const httpsAgent = new https.Agent({
        //   rejectUnauthorized: false,
        // });
          const response = await fetch(apiurl, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-type': 'application/json',
                  //'agent': httpsAgent,
              },
              body: JSON.stringify(obj),
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const result = await response.json();
          console.log(result); // Log the entire result for debugging

          // Set data directly since the response is an array
          setData((prevData) => {
            const newData = [...prevData, ...result];

            // Use a Set to filter out duplicates based on name or another unique identifier
            const uniqueData = Array.from(new Set(newData.map(item => item.name)))
                .map(name => newData.find(item => item.name === name));

            return uniqueData; // Update state with unique items
        });
          setLoading(false);
      } catch (error) {
          setError(error);
          setLoading(false);
      }
    };
    // console.log(data);
    fetchData();
  }, [searchStartid]); // Fetch data when searchStartid changes

//total of 35 plants in database
const plantNum = 55;

// Intersection Observer for detecting when to fetch more data
useEffect(() => {
  //5 plants was fetch by defualt
  var plantFetched = 5;
  
  const handleScroll = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading && plantFetched < plantNum) {
        console.log("Fetching more data..."); // Debug log
        setSearchStartid((prevId) => prevId + 5); // Increment by 5
        setLoadMore(true);
        plantFetched += 5;
        console.log(plantFetched);
    }else if(plantFetched >= plantNum){
      setLoadMore(false);
    }
};

    const options = {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 1.0, // Trigger when 100% of the target is visible
    };

    observer.current = new IntersectionObserver(handleScroll, options);
    const targetElement = document.getElementById('scroll-anchor');

    if (targetElement) {
        observer.current.observe(targetElement);
    }

    // Cleanup observer on unmount
    return () => {
        if (targetElement) {
            observer.current.unobserve(targetElement);
        }
    };
}, [loading]);

  //keyboard listener
  //when Enter is pressed, nevigate to the result page
  const searchQuery = document.getElementById('searchValue');

  //doesn't run before content render
  if(searchQuery){
    searchQuery.addEventListener('keydown', event=>{
      if(event.key === 'Enter'){
        if(document.getElementById('searchValue')){
          const value = document.getElementById('searchValue').value;
          navigate(`/SearchResult?search=${encodeURIComponent(value)}`);
        }
      }
    })
  }
  
  function handleClick(name){
    navigate(`/SearchResult?search=${encodeURIComponent(name)}`)
  }

  return (

    <div class='bg'>
      <div>
        <ToPreviousPage path='/' msg='Previous Page'/>
      </div>

      <div className='search'>
        <div className='searchInputContainer'>
          <FaSearch className='icon' size={35} style={{verticalAlign:'bottom'}}/>
          <input className='searchBar' type="text" id="searchValue" inputmode="text" placeholder='Tap to Search'/>
        </div>
        <div className='searchContainer'>
          {loading ? (
            <h3 className='errorText'>Loading...</h3>
          ) : error ? (
            <h3 className='errorText'> Error: {error.message}</h3>
          ) 
          : (
            <div>
              {data && data.length > 0 ? (
                <div>
                  {data.map((plant, index) => {
                    const imageUrl = `data:image/jpeg;base64,${plant.image}`;

                    return (
                        <button className='resultsItem' onClick={() => handleClick(plant.name)}>
                          <div className='center'>
                            {<img class="resultsImg" src={imageUrl} alt='' />}
                            {<h3 className='resultsText'>{plant.name}</h3>}
                          </div>
                        </button>
                    );
                  })}
                  <p3 className = 'message'>{loadMore && 'Loading...'}</p3>
                </div>
              ) : (
                <p className="text-white">No results found</p>
              )}
              <div id="scroll-anchor" style={{ height: '20px' }}></div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Search;
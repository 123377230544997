import React from 'react';
import './Questions.css';
import {useNavigate} from 'react-router-dom';

function SelectMethod() {
  
  const navigate = useNavigate();

  function handleClick(date){
    if (date == 0){
      localStorage.setItem('start_date','2024-10-08');
    }
    if (date == 1){
      localStorage.setItem('start_date','2024-10-07');
    }
    if (date == 3){
      localStorage.setItem('start_date','2024-10-05');
    }
    if (date == 7){
      localStorage.setItem('start_date','2024-10-01');
    }
    if (date == 14){
      localStorage.setItem('start_date','2024-09-24');
    }
    localStorage.setItem('add_date','2024-10-08');
      navigate(`/selectMethod`);
    }

  return (

    <div class='bg'>

      <h3 class='title'>When was the last time you watered this plant?</h3>
      
      <div class='questionContainer'>

            <button class='periodAndDateButton' onClick={() => handleClick(0)}>Today</button>

            <button class='periodAndDateButton' onClick={() => handleClick(1)}>Yesterday</button>

            <button class='periodAndDateButton' onClick={() => handleClick(3)}>3-4 days ago</button>

            <button class='periodAndDateButton' onClick={() => handleClick(7)}>About 1 week ago</button>

            <button class='periodAndDateButton' onClick={() => handleClick(14)}>2 weeks ago or more</button>

        </div>

    </div>
  );
}

export default SelectMethod;